<template>
  <div class="dynamic-tables">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <DataTable
      class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
      :value="filteredPowerCompanies"
      :paginator="true"
      :loading="loading"
      :rows="1000"
      dataKey="id"
      :rowHover="true"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[25, 50, 100, 1000]"
      currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen"
      :globalFilterFields="['company', 'zip', 'city']"
      filterDisplay="row"
      :filters.sync="filters"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left">
            <h1 class="page-title">{{ $t('power_companies') }}</h1>
          </div>
          <div class="table-header-right">
            <div class="button-right" v-if="isAdmin">
              <router-link
                class="btn btn-success mb-2 right"
                :to="{ name: 'PowerCompanyCreatePage' }"
              >
                <i class="fa fa-plus-circle mr-2"></i>
                {{ $t('powerCompanyCreate') }}
              </router-link>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <Column
        v-if="isAdmin"
        field="number"
        header="#"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ width: '100px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <router-link
            :to="{
              name: 'PowerCompanyEditPage',
              params: { powerCompanyNumber: slotProps.data.number },
            }"
            >{{ slotProps.data.number.toString().padStart(4, '0') }}</router-link
          >
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="company" :header="$t('company')" sortable :showFilterMenu="false">
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="zip"
        :header="$t('zip')"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ width: '85px' }"
        :bodyStyle="{ 'text-align': 'center', width: '85px' }"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="city" :header="$t('city')" sortable :showFilterMenu="false">
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="powgridRegistrationPerMailState"
        :header="$t('powgridRegistrationPerMailState_short')"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ 'text-align': 'center', width: '150px' }"
        :bodyStyle="{ 'text-align': 'center', width: '150px' }"
      >
        <template #body="slotProps">
          <b-badge :variant="yesNoColor(slotProps.data.powgridRegistrationPerMailState)">
            {{ $t(slotProps.data.powgridRegistrationPerMailState) }}
          </b-badge>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <Dropdown
            v-model="filterModel.value"
            :options="optionsYesNo()"
            class="p-column-filter"
            :showClear="true"
            @input="filterCallback()"
          >
            <template #option="slotProps">
              <b-badge :variant="yesNoColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>

      <Column
        field="powgridRegistrationOnlineState"
        :header="$t('powgridRegistrationOnlineState_short')"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ 'text-align': 'center', width: '150px' }"
        :bodyStyle="{ 'text-align': 'center', width: '150px' }"
      >
        <template #body="slotProps">
          <b-badge :variant="yesNoColor(slotProps.data.powgridRegistrationOnlineState)">
            {{ $t(slotProps.data.powgridRegistrationOnlineState) }}
          </b-badge>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <Dropdown
            v-model="filterModel.value"
            :options="optionsYesNo()"
            class="p-column-filter"
            :showClear="true"
            @input="filterCallback()"
          >
            <template #option="slotProps">
              <b-badge :variant="yesNoColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>

      <Column
        field="netRegistrationDocumentsApprovedState"
        :header="$t('netRegistrationDocumentsApprovedState_short')"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ 'text-align': 'center', width: '150px' }"
        :bodyStyle="{ 'text-align': 'center', width: '150px' }"
      >
        <template #body="slotProps">
          <b-badge :variant="yesNoColor(slotProps.data.netRegistrationDocumentsApprovedState)">
            {{ $t(slotProps.data.netRegistrationDocumentsApprovedState) }}
          </b-badge>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <Dropdown
            v-model="filterModel.value"
            :options="optionsYesNo()"
            class="p-column-filter"
            :showClear="true"
            @input="filterCallback()"
          >
            <template #option="slotProps">
              <b-badge :variant="yesNoColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>

      <Column
        field="operationRegistrationDocumentsApprovedState"
        :header="$t('operationRegistrationDocumentsApprovedState_short')"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ 'text-align': 'center', width: '150px' }"
        :bodyStyle="{ 'text-align': 'center', width: '150px' }"
      >
        <template #body="slotProps">
          <b-badge
            :variant="yesNoColor(slotProps.data.operationRegistrationDocumentsApprovedState)"
          >
            {{ $t(slotProps.data.operationRegistrationDocumentsApprovedState) }}
          </b-badge>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <Dropdown
            v-model="filterModel.value"
            :options="optionsYesNo()"
            class="p-column-filter"
            :showClear="true"
            @input="filterCallback()"
          >
            <template #option="slotProps">
              <b-badge :variant="yesNoColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import { getYesNoStateColor } from '@/helpers/colors';
import { yesNo } from '@/helpers/enums';
import { FilterMatchMode } from 'primevue/api';

export default {
  name: 'PowerCompaniesPage',
  components: {
    Breadcrumbs,
    DataTable,
    InputText,
    Column,
    Dropdown,
  },
  data() {
    return {
      loading: true,
      filters: {
        number: { value: null, matchMode: FilterMatchMode.EQUALS },
        company: { value: null, matchMode: FilterMatchMode.CONTAINS },
        zip: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        city: { value: null, matchMode: FilterMatchMode.CONTAINS },
        powgridRegistrationPerMailState: {
          value: null,
          label: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        powgridRegistrationOnlineState: {
          value: null,
          label: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        netRegistrationDocumentsApprovedState: {
          value: null,
          label: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        operationRegistrationDocumentsApprovedState: {
          value: null,
          label: null,
          matchMode: FilterMatchMode.EQUALS,
        },
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters(['isLoading', 'getPowerCompanies']),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: 'EVU' }];
    },
    filteredPowerCompanies() {
      return this.getPowerCompanies;
    },
  },
  methods: {
    ...mapActions(['fetchPowerCompanies', 'fetchPowerCompaniesForClients']),
    yesNoColor(state) {
      return getYesNoStateColor(state);
    },
    optionsYesNo() {
      return yesNo();
    },
  },
  async created() {
    if (this.isClient) {
      await this.fetchPowerCompaniesForClients();
    } else if (this.isAdmin) {
      await this.fetchPowerCompanies();
    }

    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}
.table-header-left {
  display: flex;
  justify-content: flex-start;
}
.table-header-right {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
}
/** Fix Dropdown overlay */
::v-deep .p-datatable-scrollable-header {
  overflow: visible;
}
</style>
